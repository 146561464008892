import { MessagesClassificationDto } from '../../application/types/dto/MessageClassificationDto';
import { isNotEmptyArray } from '../../utils/type-utils';
import PapiClient from '../api/PapiClient';

class MessageRepository {
  public static async classifyMessages(
    channelId: number,
    messageClassifications: MessagesClassificationDto,
  ): Promise<void> {
    if (isNotEmptyArray(messageClassifications.messages)) {
      return await PapiClient.post<void, MessagesClassificationDto>(
        `/channels/${channelId}/messages/moderation`,
        messageClassifications,
      );
    } else {
      throw new Error('No message classifications provided');
    }
  }
}

export default MessageRepository;
