import { isDefined } from '../../utils/type-utils';
import { Message } from '../types/Message';
import MessageRepository from '../../infrastructure/repositories/MessageRepository';
import { ModerationOutcome } from '../types/MessageModeration';

export interface MessageWithDefinedFlaggedOutcome extends Message {
  moderation: {
    required: true;
    flaggedOutcome: ModerationOutcome;
    outcome: ModerationOutcome | null;
  };
}

class MessageService {
  /**
   * Classifies messages based on their moderation outcome.
   *
   * This method filters the provided messages to include only those with a defined moderation outcome.
   * It then maps the filtered messages to a format suitable for classification and sends them to the MessageRepository for classification.
   *
   * @param {WithRequiredProp<Pick<Message, 'id' | 'moderation'>, 'moderation'>[]} messages - The messages to classify.
   * Each message must have an 'id' and a 'moderation' property, where 'moderation' is required.
   * @returns {Promise<void>} A promise that resolves when the classification is complete.
   */
  public async classifyMessages(channelId: number, messages: Message[]): Promise<void> {
    const messagesWithDefinedFlaggedOutcome: MessageWithDefinedFlaggedOutcome[] = messages.filter(
      (message): message is MessageWithDefinedFlaggedOutcome => {
        return isDefined(message.moderation.flaggedOutcome);
      },
    );

    await MessageRepository.classifyMessages(channelId, {
      messages: messagesWithDefinedFlaggedOutcome.map(({ id, moderation }) => ({
        id,
        moderationType: moderation.flaggedOutcome,
      })),
    });
  }
}

export const messageService = new MessageService();
